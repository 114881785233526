<template>
  <div>
    <b-card>
      <ValidationObserver ref="race" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
          <b-row>
            <b-col md="6">
              <input-form
                v-model="race.race.translations[0].name"
                :name="$t('races.nameAr')"
                :label="$t('races.nameAr')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <input-form
                v-model="race.race.translations[1].name"
                :name="$t('races.nameEn')"
                :label="$t('races.nameEn')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="6">
              <input-form
                v-model.number="race.race.distance"
                :name="$t('global.distance')"
                :label="$t('global.distance')"
                type="number"
                validate="required|decimal"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <input-form
                v-model.number="race.race.prize"
                :name="$t('global.prize')"
                :label="$t('global.prize')"
                type="number"
                validate="required|decimal"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="6">
              <main-select
                :options="competitions"
                label="name"
                v-model="race.race.competition_id"
                :reduce="(i) => i.id"
                :text="race.race.competitionName"
                :labelTitle="$t('competitions.item')"
                validate="required"
                :showAsInfo="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <main-select
                :options="tracks"
                label="name"
                v-model="race.race.track_id"
                :reduce="(i) => i.id"
                :text="race.race.trackName"
                :labelTitle="$t('tracks.item')"
                validate="required"
                :showAsInfo="disableStatus"
              />
            </b-col>

            <b-col md="6">
              <date-time-picker
                v-model="race.race.start_time"
                :name="$t('races.startTime')"
                :label="$t('races.startTime')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <date-time-picker
                v-model="race.race.end_time"
                :name="$t('races.endTime')"
                :label="$t('races.endTime')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <date-time-picker
                v-model="race.race.start_prediction_time"
                :name="$t('races.startPredictionTime')"
                :label="$t('races.startPredictionTime')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <date-time-picker
                v-model="race.race.end_prediction_time"
                :name="$t('races.endPredictionTime')"
                :label="$t('races.endPredictionTime')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="6">
              <input-form
                v-model="race.race.url"
                :name="$t('global.url')"
                :label="$t('global.url')"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="12" v-if="currentPage == itemPages.view">
              <input-form
                v-model="race.race.statusName"
                :name="$t('global.status')"
                :label="$t('global.status')"
                :disabled="disableStatus"
              />
            </b-col>
          </b-row>

          <form-btns
            v-if="currentPage != itemPages.view"
            :label="submitLabel"
            :loading="loading"
          />
        </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage != itemPages.create">
      <race-recordings
        v-if="race.race.raceRecordings.length > 0"
        :items="race.race.raceRecordings"
        :raceId="id"
        :titleStatus="true"
      />
      <page-title :title="$t('Participants')" />
      <main-table
        :fields="participantsFields"
        :list_url="`admin/race/${$route.params.id}/participants`"
        :reloadData="false"
        :createStatus="false"
        createRouteName="events.raceCreate"
        :downloadSampleStatus="false"
        :uploadFileStatus="false"
      />
    </b-card>
    <b-card v-if="currentPage != itemPages.create">
      <page-title :title="$t('Predictions')" />

      <main-table
        :fields="predictionFields"
        :list_url="`/admin/prediction/${$route.params.id}/race`"
        :reloadData="false"
        :createStatus="false"
        createRouteName="events.raceCreate"
        :downloadSampleStatus="false"
        :uploadFileStatus="false"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import raceServices from "../services/races";
import competitionsService from "../../competitions/services/competitions";
import tracksService from "../../tracks/services/tracks";
import Race from "../models/Race";
import RaceRecordings from "../../raceRecordings/views/RaceRecordings.vue";
import Participants from "../../participants/views/Participants.vue";
import moduleItemMixin from "../../../../Mixins/moduleItemMixin";
import router from "@/router";

export default {
  mixins: [moduleItemMixin],
  components: { RaceRecordings, Participants },
  data() {
    return {
      race: new Race(),
      competitions: [],
      tracks: [],
      participantsFields: [
        {
          key: "number",
          label: this.$t("participants.number"),
          sortable: true,
        },
        {
          key: "odd_form",
          label: this.$t("participants.odd"),
          sortable: true,
        },

        {
          key: "end_position",
          label: this.$t("participants.endPosition"),
          sortable: true,
        },
        {
          key: "end_time",
          label: this.$t("participants.endTime"),
          sortable: true,
        },
        {
          key: "jockeyName",
          label: this.$t("jockeys.item"),
          sortable: true,
        },
        {
          key: "horseName",
          label: this.$t("horses.item"),
          sortable: true,
        },
      ],
      predictionFields: [
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "name", label: this.$t("table.name"), sortable: true },
        { key: "amount", label: this.$t("global.amount"), sortable: true },
        { key: "status", label: this.$t("global.status"), sortable: true },
        { key: "phone", label: this.$t("global.phone"), sortable: true },

        {
          key: "created_at",
          label: this.$t("table.createdAt"),
          type: "dateTime",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    router() {
      return router;
    },
    async create() {
      raceServices
        .create({
          ...this.race.race,
        })
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "events.races" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async update() {
      raceServices
        .update(this.id, {
          ...this.race.race,
        })
        .then((response) => {
          this.loading = false;
          core.showSnackbar("success", response.data.message);
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: "events.races" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    core.index();
  },
  created() {
    if (this.id) {
      raceServices.findOne(this.id).then((response) => {
        this.race.fillData(response.data.data);
        this.loading = false;
      });
    } else {
      this.loading = false;
    }

    if (!this.disableStatus) {
      competitionsService.findAll().then((res) => {
        this.competitions = res.data.data;
      });
      tracksService.findAll().then((res) => {
        this.tracks = res.data.data;
      });
    }
  },
};
</script>
