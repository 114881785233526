export default class Race {
  constructor() {
    this.setInitialValue();
  }

  setInitialValue() {
    this.race = {
      competition_id: null,
      competitionName: "",
      track_id: null,
      trackName: "",
      distance: null,
      prize: null,
      start_time: "",
      end_time: "",
      start_prediction_time: "",
      end_prediction_time: "",
      status: "upcoming",
      statusName: "",
      url: "",
      raceRecordings: [],
      translations: [
        { id: null, name: "", lang: "ar" },
        { id: null, name: "", lang: "en" },
      ],
    };
  }

  fillData(data) {
    if (data) {
      const itemAr = data.translations?.find((o) => o.lang === "ar");
      const itemEn = data.translations?.find((o) => o.lang === "en");

      this.race.competition_id = data.competition_id
        ? +data.competition_id
        : null;
      this.race.competitionName = data.competitionName
        ? data.competitionName
        : "";
      this.race.track_id = data.track_id ? +data.track_id : null;
      this.race.trackName = data.trackName ? data.trackName : "";
      this.race.distance = data.distance ? data.distance : "";
      this.race.prize = data.prize ? data.prize : "";
      this.race.start_time = data.start_time ? data.start_time : "";
      this.race.end_time = data.end_time ? data.end_time : "";
      this.race.start_prediction_time = data.start_prediction_time
        ? data.start_prediction_time
        : "";
      this.race.end_prediction_time = data.end_prediction_time
        ? data.end_prediction_time
        : "";
      this.race.status = data.status ? data.status : "upcoming";
      this.race.statusName = data.statusName ? data.statusName : "";
      this.race.url = data.url ? data.url : "";
      this.race.raceRecordings = data.raceRecordings ? data.raceRecordings : [];
      this.race.translations[0].id = itemAr ? itemAr.id : null;
      this.race.translations[0].name = itemAr ? itemAr.name : "";
      this.race.translations[1].id = itemEn ? itemEn.id : null;
      this.race.translations[1].name = itemEn ? itemEn.name : "";
    } else {
      this.setInitialValue();
    }
  }
}
