<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('raceRecordings.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/race-recording'"
        :items="items"
        :reloadData="reloadData"
        :params="filter"
        :createBtnLabel="$t('raceRecordings.create')"
        createRouteName="events.raceRecordingCreate"
        :createRouteParams="{ raceId: raceId }"
        :excelFields="excelFields"
        excelName="raceRecordings"
        :downloadSampleStatus="true"
        downloadSampleUrl="race-recording/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="race-recording/excel"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import RaceRecordingFilter from "../models/RaceRecordingFilter";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      filter: new RaceRecordingFilter(),
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        {
          key: "title",
          label: this.$t("raceRecordings.title"),
          sortable: true,
        },
        {
          key: "cover_image",
          label: this.$t("global.coverImage"),
          type: "image",
        },
        { key: "url", label: this.$t("global.url"), sortable: true },
        { key: "raceName", label: this.$t("races.item"), sortable: true },
        {
          key: "created_at",
          label: this.$t("table.createdAt"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "events.raceRecordingView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.update"),
              icon: "fas fa-pen",
              color: "primary",
              ifNavigate: true,
              routeName: "events.raceRecordingUpdate",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.delete"),
              icon: "fas fa-trash-alt",
              color: "danger",
              showAlert: true,
              actionHeader: this.$t("global.delete"),
              titleHeader: this.$t("raceRecordings.item"),
              textContent: "name",
              url: "admin/race-recording",
              type: "delete",
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("raceRecordings.title")]: "title",
        [this.$t("global.url")]: "url",
        [this.$t("races.item")]: "raceName",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },
  props: {
    items: { type: Array, default: () => [] },
    raceId: { type: String, default: "" },
  },
  mounted() {
    core.index();
  },
};
</script>
