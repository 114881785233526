import GeneralFilter from '../../../../Model/GeneralFilter'

export default class RaceRecordingFilter extends GeneralFilter {
  constructor () {
    super()
    this.setInitialValue()
  }

  setInitialValue () {
    this.setFilterInitialValue()
  }
}
